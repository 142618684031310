import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { environment } from './../../environments/environment';
import ISDCodeJSON from '../../assets/json/isd_codes.json';
@Injectable({
  providedIn: 'root'
})
export class ApplicantInfoService {
  constructor(private http: HttpClient) {}
  public apiEndPoint: any;

  public getInviteApplicant(kioskType, custId, custTag, email, source) {
    this.apiEndPoint = environment.api_endpoint;
    const path = this.apiEndPoint + '/web/v1';
    let inviteGetURL = '';
    inviteGetURL = `${path}/kiosk/${kioskType}/${custId}/${custTag}/invite?email=${email}&source=${source}` ;
    return this.http
      .get( inviteGetURL )
      .pipe(
        map(res => this._extractNewData(res)),
        map(res => this._doGetAction(res)),
        catchError(this._handleError)
      );
  }


  public getConfig(kioskType, custId, custTag, languageCd?) {
    this.apiEndPoint = environment.api_endpoint;
    let obj;
    obj = {
      url : custId,
      path : custTag
    };

    if (languageCd) {
       obj.language = languageCd;
     }

    const params = new HttpParams({fromObject: obj});
    // const path = AppConfig.API_ENDPOINT() + '/web/v1';

    const path = this.apiEndPoint + '/web/v1';
    let kioskGetURL = '';

    if (kioskType) {
      kioskGetURL = `${path}/kiosk/${kioskType}/${custId}/${custTag}`;
    } else {
      // Deprecated - Move to above format soon. kioskType = bg/background
      if (languageCd) {
        kioskGetURL = `${path}/kiosk?url=${custId}&path=${custTag}&language=${languageCd}`;
      } else {
        kioskGetURL = `${path}/kiosk?url=${custId}&path=${custTag}`;
      }
    }

    return this.http
    //If queryparam used in this api
      // .get(path + '/kiosk', { params })
      .get( kioskGetURL )
      .pipe(
        map(res => this._extractNewData(res)),
        map(res => this._doGetAction(res)),
        catchError(this._handleError)
      );
//   "customer_name": "PA QA Account"
// });
    // const response = {
    //   welcome_msg : '<-- Company name and Welcome msg which is set in CDC Admin should display here -->',
    //   welcome_header : 'Please provide your full name as per your identification and legal documents.',
    //   isSMSPrefOn : true
    // };

    // return response;
  }

  private _extractNewData(res) {
    return (res) || { };
  }

  private _doGetAction(res) {
    return res;
  }

  private _handleError(error: HttpErrorResponse) {
    let errorMessage: string;
    let errorCode = 'BAD_REQUEST';
    switch (error.status) {
      case 400:
        break;
      case 401:
        errorMessage = error.error || 'Unauthorized';
        break;
      case 404:
        break;
      case 410:
        errorMessage = error.error || 'Kiosk link has expired';
        errorCode = 'LINK_EXPIRED';

    }
    // return Observable.throw({errorMessage, errorCode});
    return throwError({errorMessage, errorCode});
  }
  public submitApplicantInfo(kioskType, custId, custTag, customerName, source, obj) {
    this.apiEndPoint = environment.api_endpoint;
    // const path = AppConfig.API_ENDPOINT() + '/web/v1';
    const path = this.apiEndPoint + '/web/v1';

    let kioskPostURL = ""

    if ( kioskType && kioskType.toLowerCase() === 'rightid') {
      kioskPostURL = `${path}/kiosk/${kioskType}/${custId}/${custTag}?source=${source}` ;
      let objArr =  {
        "kiosk-applicant-list": obj,
        "customer_name" : customerName
      };
      console.log("submitApplicantInfo  objArr ", objArr);
      obj = objArr; 
    } else {
      kioskPostURL = `${path}/kiosk/`
    }
    

    return this.http.post(kioskPostURL, obj)
    .pipe(
      map(res => this._extractNewData(res)),
      map(res => this._doGetAction(res)),
      catchError(this._handleError)
    );
    // const response = {
    //   redirect_url: 'https://pa-intl-qa.fadv.net/#/invite',
    //   applicant_id: 'UKA24E6JRM_005'
    // };

    // return response;
  }

  countryCodeLookUpService(val): Observable<any> {
    const isdCodesList = ISDCodeJSON;
    if (val.includes('+')) {
        val = val.replace('+', '');
    }
    const data = isdCodesList.filter(isd => isd.code.includes(val) || isd.country.toLowerCase().includes(val.toLowerCase()) );
    return of(data && data.length > 0 ? data : []);
  }

  static getRelationShipOptions(): Array<Object> {
    let types = [
      { id: 'Spouse', name: 'APPLICANT_SPOUSE' },
      { id: 'Guarantor', name: 'APPLICANT_GUARANTOR' },
      { id: 'Other', name: 'APPLICANT_OTHER' },
    ];
    return types;
  }  

  getAccountInfo(inviteType: string, accountId: string, accountTag: string, inviteSource: string, countryCode: string, languageCode: string): Observable<any> {
    console.log("Inside getAccountInfo method");
    this.apiEndPoint = environment.api_endpoint;
     return this.http
                 .get(this.apiEndPoint+ `/api/web/account/${inviteType}/${accountId}/${accountTag}` + '?source=' + `${inviteSource}` + '&country_cd=' + countryCode + '&language_cd=' + languageCode)
                 .pipe(
                   map(res => this._extractNewData(res)),
                   map(res => this._doGetAction(res)),
                   catchError(this._handleError));
   }

   getAccountForm(inviteType: string, accountId: string, accountTag: string, inviteSource: string, countryCode: string, languageCode: string): Observable<any> {
    console.log("Inside getAccountInfo method");
    this.apiEndPoint = environment.api_endpoint;
     return this.http
                 .get(this.apiEndPoint+ `/api/web/account/${inviteType}/${accountId}/${accountTag}` + '?source=' + `${inviteSource}` + '&country_cd=' + countryCode + '&language_cd=' + languageCode)
                 .pipe(
                   map(res => this._extractNewData(res)),
                   map(res => this._doGetAction(res)),
                   catchError(this._handleError));
   }
}

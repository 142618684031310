import { Component, OnInit } from '@angular/core';
import { EventBusService } from '../shared/event-bus/event-bus.service';
import { TranslationService } from '../shared/translation/translation.service';
declare const jQuery: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private es: EventBusService, private ts: TranslationService) { }
  public termsOfServicesData: string;
  public termsOfServiceHeader: string;
  public termsOfSeviceLabel:string;
  public privacyPolicyLabel:string;

  ngOnInit() {
    this.es.availableLanguageAnnounced$.subscribe(data => {
      this.termsOfServicesData = this.ts.getTransltions('TERMS_AND_CONDITIONS_CONTENT');
      this.termsOfServiceHeader = this.ts.getTransltions('TERMS_AND_CONDITION_HEADER');
      this.termsOfSeviceLabel = this.ts.getTransltions('FOOTER_TERMS_SERVICE');
      this.privacyPolicyLabel = this.ts.getTransltions('FOOTER_PRIVACY_POLICY');
    });
  }

  private openTermsServices() {
    jQuery('#termsServices').modal('show');
  }

}

import { Injectable, Inject } from '@angular/core';
import { TRANSLATIONS, TRANSLATIONS_DB } from './translations';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public currentLang: string;

  constructor(
    @Inject(TRANSLATIONS) private translationToken: string,
    @Inject(TRANSLATIONS_DB) private translationsFromDBToken: string
  ) {}

  public translate(key, words?: string | string[]) {
    const translation = this.getTransltions(key);
    if (!words) {
      return translation;
    }

    return this.replace(translation, words); // call replace function
  }

  public replace(word: string = '', words: string | string[] = '') {
    let translation: string = word;
    const PLACEHOLDER = '%';
    const values: string[] = [].concat(words);
    values.forEach((e, i) => {
      translation = translation.replace(PLACEHOLDER.concat(i as any), e);
    });

    return translation;
  }

  public getTransltions(key: string): string {
    // private perform translation
    const translation = key;
    // this.currentLang = 'en';
    if (
      this.translationsFromDBToken[this.currentLang] &&
      this.translationsFromDBToken[this.currentLang][key]
    ) {
      return this.translationsFromDBToken[this.currentLang][key];
    } else {
      if (
        this.translationToken[this.currentLang] &&
        this.translationToken[this.currentLang][key]
      ) {
        return this.translationToken[this.currentLang][key];
      }
    }

    return translation;
  }

  setLanguage(ln) {
    this.currentLang = ln;
  }

  getLanguage() {
    return this.currentLang;
  }

}

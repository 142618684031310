import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiKey = environment.apikey; // get the api key from the environment
    let newHeaders = request.headers;
    if (apiKey) {
      newHeaders = newHeaders.append('apikey', apiKey); // add the apikey to the existing headers
    }
    const authenticatedReq = request.clone({ headers: newHeaders }); // clone the request as requests are immutable, and add the new headers
    return next.handle(authenticatedReq); // pass the newly cloned request on
  }
}
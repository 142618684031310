import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

declare var jQuery: any;
@Component({
  selector: 'language-picker',
  templateUrl: './language-picker.component.html'
})

export class LanguagePickerComponent implements OnInit {

  @Input() languagePickerConfig: any;
  @Output() onLanguagePick: EventEmitter<any> = new EventEmitter();

  selectedLanguage: String = '';

  constructor() { }

  ngOnInit() {
    console.log('language List: ' + JSON.stringify(this.languagePickerConfig));
    this.setDefaultLanguage();
  }

  setDefaultLanguage() {
    for(let option of this.languagePickerConfig) {
      if(option['highlightId'] === 'activeLanguageList') {
        this.selectedLanguage = option['lang_contry_cd'];
      }
    }
  }

  setUpdatedLanguage(language){
    for(let option of this.languagePickerConfig) {
        if(option['lang_contry_cd'] === language) {
          this.onLanguagePick.emit(option);
        }
    }
  }

  ngAfterContentChecked() {
    jQuery('.selectpicker').selectpicker();
  }

  ngAfterViewChecked() {
    jQuery('.selectpicker').selectpicker('refresh');
  }
}

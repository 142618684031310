import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './shared.service';
import { TranslatePipe } from './translation/translate.pipe';
import { EventBusService } from './event-bus/event-bus.service';
import { TranslationService } from './translation/translation.service';
import { TRANSLATION_PROVIDERS } from './translation/translations';

@NgModule({
  declarations: [TranslatePipe],
  imports: [CommonModule],
  exports: [TranslatePipe],
  providers: [SharedService, EventBusService, TranslationService, TRANSLATION_PROVIDERS]
})
export class SharedModule {}

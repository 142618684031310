import { Component, OnInit, Inject } from '@angular/core';
import { TrackEventService } from './shared/track-event/track-event-shared.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'kiosk';

  constructor(public trackEventService: TrackEventService,
              @Inject(DOCUMENT) private document: any) {
    let body = this.document.getElementsByTagName('body')[0];
    let myScript = document.createElement("script");
    
    myScript.setAttribute("type", "module");
    myScript.setAttribute("src", "./../assets/js/fadv-support-app.cjs.production.min.js");
    body.appendChild(myScript);

    let customScript = document.createElement("fadv-support-app");
    body.appendChild(customScript);
  }

  ngOnInit() {
  }

}

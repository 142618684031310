import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  public errorCode: string;
  public errorMessage: string;
  constructor(private sharedService: SharedService) { }
  ngOnInit() {
      this.errorCode = this.sharedService.getErrorCode();
      this.errorMessage = this.sharedService.getErrorMessage();
  }

}

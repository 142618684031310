import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-candidate-confirm',
  templateUrl: './candidate-confirm.component.html',
  styleUrls: ['./candidate-confirm.component.scss']
})
export class CandidateConfirmComponent implements OnInit {

  @Input('group')
  public registerSubForm: FormGroup;
  @Input() row: any;

  @Output() onCandidate: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    console.log("Candidate Confirm  ");
  }

  additionalCandidate(): void {
    let obj = this.buildConfirmationObj('true');
    this.onCandidate.emit(obj);
  }

  noMoreCandidate(): void {
    let obj = this.buildConfirmationObj('false');
    this.onCandidate.emit(obj);
  }

  buildConfirmationObj(hasMore: string) {
    let obj: Object = {};
    let indexDetail: Object = {};
    indexDetail['row'] = this.row;
    obj['has_candidate'] = hasMore;
    obj['indexDetail'] = indexDetail;
    return obj;
  }

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_endpoint: 'https://pa-ua.fadv.com',
  client_url: 'https://kiosk-ua.fadv.com',
  apiUrl: 'https://pa-ua.fadv.com',
  consent_enabled: true,
  apikey:'28cSb6Jt1o1Xc1CRtkUdDTQdQ2STWUD2',
  salesForceInitializeUrl: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
  salesForceChatSettings: {
    displayHelpButton: true,
    language: '',
    defaultMinimizedText: '',
    disabledMinimizedText: '',
    loadingText: '',
    storageDomain: '',
    prepopulatedPrechatFields: {},
    enabledFeatures: ['LiveAgent'],
    entryFeature: 'LiveAgent',
    initEnvUrl: 'https://firstadv--staging.sandbox.my.salesforce.com',
    initAgentUrl: 'https://firstadv--staging.sandbox.my.site.com',
    initParam1: '00D7e00000Jywkx',
    initAgentName: 'Customer_Care_Chat_Clone',
    customSettings: {
      baseLiveAgentContentURL: 'https://c.la3-c1cs-ia5.salesforceliveagent.com/content',
      deploymentId: '5725a000000Goie',
      buttonId: '5735a000000oLor',
      baseLiveAgentURL: 'https://d.la3-c1cs-ia5.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I7e00000000ZcEAI_18abd7a271c',
      isOfflineSupportEnabled: false
    }
  },
  salesForceChatEmbeddedServiceMinJS: 'https://firstadv--staging.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  salesForceChatBaseUrl: 'https://service.force.com',
  salesForceChatPosition: 'left',
  default_chat_exp : 'SFC',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

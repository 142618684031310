import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApplicantInfoComponent } from './applicant-info/applicant-info.component';
import { ErrorComponent } from './error/error.component';

const routes: Routes = [
  { path: '', component: ApplicantInfoComponent },
  { path: ':cust_id/:cust_tag', component: ApplicantInfoComponent },
  { path: ':kioskType/:cust_id/:cust_tag', component: ApplicantInfoComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', redirectTo : '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export class LanguageConstants {
    static diplayLanguageList = {
        'bg': {languageName: 'Bulgarian', flagIconName: 'bg'},
        'cs': {languageName: 'Czech', flagIconName: 'cz'},
        'de': {languageName: 'Deutsch', flagIconName: 'de'},
        'en': {languageName: 'English', flagIconName: 'us'},
        'en-gb': {languageName: 'English', flagIconName: 'gb'},
        'es': {languageName: 'Español LatAm', flagIconName: 'mx'},
        'es-es': {languageName: 'Español', flagIconName: 'mx'},
        'es-419': {languageName: 'Español LatAm', flagIconName: 'mx'},
        'fr': {languageName: 'Français', flagIconName: 'fr'},
        'fr-ca': {languageName: 'Français canadien', flagIconName: 'ca'},
        'hu' : {languageName: 'Magyar', flagIconName: 'hu'},
        'id': {languageName: 'Indonesian', flagIconName: 'id'},
        'it': {languageName: 'Italiano', flagIconName: 'it'},
        'ja': {languageName: '日本語', flagIconName: 'jp'},
        'ko': {languageName: 'Korean', flagIconName: 'kr'},
        'nl': {languageName: 'Dutch', flagIconName: 'nl'},
        'pl': {languageName: 'Polski', flagIconName: 'pl'},
        'pt-pt' : {languageName: 'Português', flagIconName: 'pt'},
        'pt-br' : {languageName: 'Português LatAm', flagIconName: 'br'},
        'ro': {languageName: 'Romanian', flagIconName: 'ro'},
        'sk': {languageName: 'Slovak', flagIconName: 'sk'},
        'sv-se': {languageName: 'Swedish', flagIconName: 'se'},
        'th': {languageName: 'Thai', flagIconName: 'th'},
        'tr': {languageName: 'Turkish', flagIconName: 'tr'},
        'vi': {languageName: 'Vietnamese', flagIconName: 'vn'},
        'zh-cn': {languageName: '简体中文', flagIconName: 'cn'},
        'zh-hk': {languageName: '繁體中文', flagIconName: 'hk'},
        'zh-sg': {languageName: '简体中文 Singapore', flagIconName: 'cn'},
        'zh-my': {languageName: '简体中文 Malaysia', flagIconName: 'cn'},
        'zh-tw': {languageName: '繁體中文-台灣', flagIconName: 'tw'},
    };

    static suppoetedLanguagesList = {
        'bg': 'bg',
        'cs': 'cs',
        'de': 'de',
        'de-de': 'de',
        'en-us': 'en',
        'en-gb': 'en-gb',
        'es': 'es-419',
        'es-us': 'es-419',
        'es-es': 'es-es',
        'es-419': 'es-419',
        'es-mx': 'es-419',
        'es-ar': 'es-419',
        'es-bo': 'es-419',
        'es-cl': 'es-419',
        'es-co': 'es-419',
        'es-cr': 'es-419',
        'es-do': 'es-419',
        'es-ec': 'es-419',
        'es-sv': 'es-419',
        'es-gt': 'es-419',
        'es-hn': 'es-419',
        'es-ni': 'es-419',
        'es-pa': 'es-419',
        'es-py': 'es-419',
        'es-pe': 'es-419',
        'es-pr': 'es-419',
        'es-uy': 'es-419',
        'es-ve': 'es-419',
        'ko': 'ko',
        'nl': 'nl',
        'fr': 'fr',
        'fr-gf': 'fr',
        'fr-ca': 'fr-ca',
        'hu': 'hu',
        'hu-hu': 'hu',
        'id': 'id',
        'it': 'it',
        'it-it': 'it',
        'ja': 'ja',
        'ja-jp': 'ja',
        'pl': 'pl',
        'pl-pl': 'pl',
        'pt': 'pt-br',
        'pt-pt': 'pt',
        'pt-br': 'pt-br',
        'ro': 'ro',
        'sk': 'sk',
        'sv-se': 'sv-se',
        'th': 'th',
        'tr': 'tr',
        'vi': 'vi',
        'zh': 'zh-cn',
        'zh-hk': 'zh-hk',
        'hk': 'zh-hk',
        'zh-sg': 'zh-sg',
        'zh-tw': 'zh-tw',
        'zh-hans': 'zh-cn',
        'zh-cn': 'zh-cn',
        'zh-my': 'zh-my',
        'zu': 'zu'
      }; 
}
import { LanguageConstants } from './shared/language-constant/language-constants';

export enum ApplicantionConstants  {
  APPLICANT = 'Applicant'
};

export class AppConfig {

    static API_ENDPOINT(): string {
        return 'https://pa-intl-qa.fadv.net';
    }

    static getDefaultLanguage() {
        return 'en';
    }

// Supported Languages
static getSupportedLanguageList() {
    return LanguageConstants.suppoetedLanguagesList;
  }


  public static getDisplayLanguages() {
    return LanguageConstants.diplayLanguageList;
  }
}

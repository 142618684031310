import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ApplicantInfoComponent } from './applicant-info/applicant-info.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ErrorComponent } from './error/error.component';
import { environment } from './../environments/environment';

// Import Common Service
import { SharedModule } from './shared/shared.module';
import { TrackEventService } from './shared/track-event/track-event-shared.service';
import { CandidateComponent } from './applicant-info/candidate/candidate.component';
import { CandidateConfirmComponent } from './applicant-info/candidate-confirm/candidate-confirm.component';
import { ConsentComponent } from './applicant-info/consent/consent.component';

// Import Application wide shared services
import { LanguagePickerModule } from '../framework/languagePicker';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignaturePadExtendedModule} from '@fadv/signature-pad-extended';
import { AuthInterceptorService } from './shared/auth-interceptor.service';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ApplicantInfoComponent,
    ErrorComponent,
    CandidateComponent,
    CandidateConfirmComponent,
    ConsentComponent
  ],
  // exports: [FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    LanguagePickerModule,
    SignaturePadModule,
    SignaturePadExtendedModule,
    NgSelectModule,
    DeviceDetectorModule.forRoot()
  ],
  providers: [
    TrackEventService,
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export class ApplicantInfoConfig {
    static FONT_BASED_SIGNATURE_PREF = "1453";
    static steps: any = [
        'candidate',
        'consent',
        'candidate_confirm',
    ];

    static getSteps(): any {
        return ApplicantInfoConfig.steps;
    }
}

import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList, ElementRef, ViewChild} from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { SignaturePadExtendedComponent } from 'projects/fadv/signature-pad-extended/src/public-api';
import { SharedService } from '../../shared/shared.service';
import { Router } from '@angular/router';
import { LocalStorageService } from 'projects/fadv/web-common/src/public-api';
import { TranslationService } from '../../shared/translation/translation.service';
import { TrackEventService } from '../../shared/track-event/track-event-shared.service';
import { BehaviorSubject } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss']
})
export class ConsentComponent implements OnInit {

  private _data = new BehaviorSubject<any>([]);
  @Input() row: any;
  @Output() onAcceptConsent: EventEmitter<any> = new EventEmitter();
  @ViewChildren('signaturePadExtendedContainer') public signaturePadExtendedContainer: QueryList<SignaturePadExtendedComponent>;
  @ViewChild('signatureActionsBar') signatureActionsBar: ElementRef;
  public confirmSignatureActions = false;
  public signatureActions = true;

  @Input() customerName: string;
  @Input('group') public form: FormGroup;
  @Input() consentHTML: string;
  @Input()
  set candidateDetailsEntered(value) {
    this._data.next(value);
  };

  get candidateDetailsEntered() {
    return this._data.getValue();
  }

  signatureValue: any;
  disableSignCleanButton: boolean = true;
  displayLabels: any;
  signaturePadNote: any;
  confirmSignatureText: string;
  declineESigConfirmText: string;
  declineESigCText: string;
  redirectURL: string;
  public confirmSignatureFlag = false;
  useFontBasedSignature: boolean = false;
  signatureFont: string = '';
  signatureLabel: string = '';
  signatureInitialValue: string = '';
  displaySignaturePad : boolean = false;
  
  constructor(private sharedService: SharedService, private router: Router, private localStorage: LocalStorageService,
    private ts: TranslationService, public trackEventService: TrackEventService) {
  }

  ngOnInit() {
    console.log("Consent init..");
    this.signatureValue = '';

    this._data
      .subscribe(x => {
        if (!!x) {
          this.useFontBasedSignature = this.localStorage.getItem('use_font_based_signature') === 'YES';
          console.log("consent.component useFontBasedSignature ", this.useFontBasedSignature);
          if (this.useFontBasedSignature) {
            this.signatureFont = this.localStorage.getItem('SIGNATURE_FONT');
            this.signatureLabel = this.ts.translate('FONT_BASED_SIGNATURE');
            let firstName = this.form.controls.first_name.value;
            let lastName = this.form.controls.last_name.value;
            let fullname = `${firstName} ${lastName}`;
            this.signatureInitialValue = fullname;
          }
          this.displaySignaturePad = true;
        }
      });
  }

  public ngAfterViewInit() {
    this.confirmSignatureActions = false;
    this.signatureActions = true;
    this.signaturePadExtendedContainer.first.setPenColor("black");
  }

  closeDecline(){
    $('#declineEsignature_Modal').modal('hide');
    $('.modal-backdrop').remove();
  }

  showDeclineESignaturePopup(){
    this.declineESigConfirmText = this.localStorage.getItem('decline_esignature_confirm_text');
    this.declineESigCText = this.localStorage.getItem('decline_esignature_text');
    this.redirectURL = this.localStorage.getItem("redirect_url");

    if (this.declineESigConfirmText === undefined || this.declineESigConfirmText === ''){
      this.declineESigConfirmText = this.ts.translate('DECLINE_ESIGNATURE_CONFIRM_TEXT');
    }
    if (this.declineESigCText === undefined || this.declineESigCText === ''){
      this.declineESigCText = this.ts.translate('DECLINE_ESIGNATURE_TEXT');
    }
    $('#declineEsignature_Modal').modal('show');
  }

  confirmDeclineESignature(){
    $('#declineEsignature_Modal').modal('hide');
    $('.modal-backdrop').remove();
    this.trackEventService.updateTrackEvent('kiosk rightid consent declined', { stage_name: 'kiosk consent', action_value: 'esignature decline'});

    if (this.redirectURL != undefined && this.redirectURL != '') {
      let accountId = this.localStorage.getItem('account_id');
      let tagId = this.localStorage.getItem('tag_id');
      let url = new URL(this.redirectURL);
      var search_params = url.searchParams;
      search_params.set('action', 'DECLINED');
      search_params.set('accountid', accountId);
      search_params.set('tagid', tagId);
      let new_url = url.toString();
      $('#plsWaitOnIAgreeModal').modal('show');
      window.location.href = new_url;
      return;
    } else {
      this.sharedService.setErrorCodeAndMsg('MESSAGE', this.declineESigCText);
      this.router.navigate(['error']);
    }
  }

  esignature() {
    let firstName = this.form.controls.first_name.value;
    let lastName = this.form.controls.last_name.value;
    let fullname = `${firstName} ${lastName}`;
    this.displayLabels = [fullname];
    this.signaturePadNote =  this.ts.translate('E_SIGNATURE_PAD_NOTE');
    this.confirmSignatureText =  this.ts.translate('E_SIGNATURE_PAD_CONFIRM_TEXT');
    if (this.useFontBasedSignature) {
      this.signatureValue = this.signaturePadExtendedContainer.first.getSignature();
    } else {
      this.signaturePadExtendedContainer.first.setSignature(this.signatureValue);
    }
    if (this.signatureValue  == undefined || this.signatureValue  == "" || this.signatureValue  == null){
      this.disableSignCleanButton = true;
    } else {
      this.disableSignCleanButton = false;
    }
    this.signaturePadExtendedContainer.first.signatureContent.nativeElement.classList.remove("confirm-sign-animate-right");
    this.signatureActionsBar.nativeElement.classList.remove("confirm-sign-animate-right");
    $('#signature_Modal').modal('show');
  }

  modalSignatureClose(){
    $('#signature_Modal').modal('hide');
    $('.modal-backdrop').remove();
  }

  onSign(){
    console.log('On Sign...')
    this.trackEventService.updateTrackEvent('kiosk rightid consent sign', { stage_name: 'kiosk consent', action_value: 'sign clicked'});
    this.signaturePadExtendedContainer.first.promptConfirmSignature();
    this.confirmSignatureActions = true;
    this.signatureActions = false;
  }

  onConfirmSignGoBack(){
    this.signaturePadExtendedContainer.first.hideConfirmSignature();
    this.signatureActions = true;
    this.signatureActionsBar.nativeElement.classList.add("confirm-sign-animate-right");
    this.confirmSignatureActions = false;
  }

  onConfirmSign(){
    console.log('On Confirm Sign...')
    this.modalSignatureClose();
    this.signaturePadExtendedContainer.first.hideConfirmSignature();
    this.signatureActions = true;
    this.confirmSignatureActions = false;
    this.signatureValue = this.signaturePadExtendedContainer.first.getSignature();
    var obj = {
      consent: 'ACCEPTED',
      consent_date :  new Date().getTime(),
      consent_signature:  this.signatureValue
    }
    this.onAcceptConsent.emit(obj);
    this.trackEventService.updateTrackEvent('kiosk rightid consent cofirm sign', { stage_name: 'kiosk consent', action_value: 'confirm sign clicked'});
  }

  onSignCancel(){
    console.log('On Sign Cancel...')
    this.onConfirmSignGoBack();
    this.modalSignatureClose();
    this.onSignClear();
  
  }

  onSignClear(){
    console.log('On Sign Clear...')
    this.signaturePadExtendedContainer.first.clear();
    this.signatureValue = "";
    this.disableSignCleanButton = true;
  }

  onDrawComplete(obj: any){
    let tempSgnatureValue = this.signaturePadExtendedContainer.first.getSignature();
    if (tempSgnatureValue == undefined || tempSgnatureValue == "" || tempSgnatureValue == null){
      this.disableSignCleanButton = true;
    } else {
      this.disableSignCleanButton = false;
    }

  }
}

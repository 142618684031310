import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApplicantInfoService } from '../applicant-info.service';
import { debounceTime, filter, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { ApplicantionConstants } from 'src/app/app-config';
import ISDCodeJSON from '../../../assets/json/isd_codes.json';
import { LocalStorageService } from 'projects/fadv/web-common/src/public-api';



@Component({
  selector: 'app-candidate',
  templateUrl: './candidate.component.html',
  styleUrls: ['./candidate.component.scss']
})
export class CandidateComponent implements OnInit {

  @Input('group')
  public registerSubForm: FormGroup;
  @Input() row: any;
  @Input() isSMSPrefOn: boolean;
  @Input() isRightId: boolean;

  @Output() onCandidate: EventEmitter<any> = new EventEmitter();

  public isInvalidEmailID = false;
  public isValidPhone = true;
  public enableNextButton = false;
  public isNumberList  = true;
  public isEmailError = false;
  public countrycodelist: any;
  public codeErrorMsg = false;
  public emailErrorMessage = '';
  relationShips: any;  
  selectedValue: any ;
  isdCodesList: any;

  constructor(private applicantInfoService: ApplicantInfoService,
    private localStorage: LocalStorageService) { }

  ngOnInit() {
    this.isdCodesList = ISDCodeJSON;
    console.log("app-candidate registerSubForm ", this.registerSubForm);
    this.relationShips = ApplicantInfoService.getRelationShipOptions();
    // if(this.isSMSPrefOn) {
    //   this.searchCountryCode();
    // }
    if (this.isRightId) {
      this.registerSubForm.controls.countryCode.setValue("+1");
    }

    // this.selectedValue = this.registerSubForm.controls.countryCode.value;
  }

  ngAfterViewInit() {
    let email = this.registerSubForm.controls['email'];
    if (!!email.value) {
      this.validateEmail(email.value);
    }
  }

  validateCountryCd(val) {
    let validCd = false;
    if (val && val.length > 0) {
       validCd = true;
      } else if (val && val.length === 0) {
        this.isNumberList  = true;
        this.enableNextButton = false;
      }
    return validCd;
  }
  
  submitData(obj) {
    console.log("candidate component obj", obj);
    if (obj['applicant_type'] === undefined) {
      obj['applicant_type'] = ApplicantionConstants.APPLICANT.toString();
    }
    
    this.localStorage.setItem('email', obj['email']);
    this.onCandidate.emit(obj);
  }

  public keyupCountryCode(data) {
    if (!!data && data.length > 0) {
      const body = data;
      this.countrycodelist = this.applicantInfoService.countryCodeLookUpService(body).pipe(
        map(data => {
          if (data.length > 0) {
            this.codeErrorMsg = false;
            this.enableNextButton = true;
          } else {
            this.codeErrorMsg = true;
            this.enableNextButton = false;
          }
          this.isNumberList = false;
          return data;
        }
        ));
    } else if (!!data && data.length === 0) {
            this.isNumberList  = true;
            this.enableNextButton = false;
    }
  }

  focusOutFunction(data) {
    if (data !== '') {
        const regex = /^[0-9]*$/;
        if (regex.test(data) === true) {
          const str1 = '+';
          data = str1.concat(data);
          this.registerSubForm.controls.countryCode.setValue(data);
          this.enableNextButton = true;
       // this.codeErrorMsg = false;
        } else {
          const regex1 = /^([+]\d+(\.\d+)?)/;
          if (regex1.test(data) === false) {
            this.registerSubForm.controls.countryCode.setValue('');
            this.codeErrorMsg = true;
            this.enableNextButton = false;
          } else {
            this.codeErrorMsg = false;
            this.enableNextButton = true;
          }
        }
        this.isNumberList = true;
    }
  }

  public closePhoneError() {
    this.isValidPhone = true;
  }

  public closeEmailError() {
    this.isEmailError = false;
  }

  public closeCodeError() {
    this.codeErrorMsg = false;
  }
  
  enterCityInput(data) {
    this.registerSubForm.controls.countryCode.setValue(data.code);
    this.isNumberList = true;
    this.codeErrorMsg = false;
    this.enableNextButton = true;
  }  

  validateEmail(value: string) {
    const inputEmail = value;
    // tslint:disable-next-line:max-line-length
    const emailRegex = new RegExp('^[_A-Za-z0-9-!@*#$&()=\\-`.+,/\]+(\\.[_A-Za-z0-9-!@#*$&()\\-=.+,/\]+)*@[_A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');

    if (inputEmail === '') {
        this.isEmailError = true;
        this.isInvalidEmailID = true;
        this.emailErrorMessage = 'Please enter email.';
        this.enableNextButton = false;
    } else if (!emailRegex.test(inputEmail)) {
        this.isEmailError = true;
        this.isInvalidEmailID = true;
        this.emailErrorMessage = 'Please enter valid email.';
        this.enableNextButton = false;
    } else {
        this.isEmailError = false;
        this.isInvalidEmailID = false;
        this.emailErrorMessage = '';
        this.enableNextButton = true;
    }
  }

  public keyupPhoneNumber() {
    this.isNumberList = true;
  }  
  
  public validatePhone(input) {
    const phoneRegex = /^(([0-9]{3})([-\s]{1})?([0-9]{3})([-\s]{1})?([0-9]{4}))|((\({1})([0-9]{3})(\){1})([-\s]{1})?([0-9]{3})([-\s]{1})?([0-9]{4}))$/i;
    if (input === '' || input === undefined) {
        this.isValidPhone = false;
    } else if (input !== undefined && !phoneRegex.test(input)) {
        this.isValidPhone = false ;
    } else {
        this.isValidPhone = true;
    }
  }

  validateEmailOnKeyUp(value: string) {
    const inputEmail = value;
    // tslint:disable-next-line:max-line-length
    const emailRegex = new RegExp('^[_A-Za-z0-9-!@*#$&()=\\-`.+,/\]+(\\.[_A-Za-z0-9-!@#*$&()\\-=.+,/\]+)*@[_A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');

    if (inputEmail === '') {
        this.isInvalidEmailID = true;
        this.enableNextButton = false;
    } else if (!emailRegex.test(inputEmail)) {
        this.isInvalidEmailID = true;
        this.enableNextButton = false;
        this.isEmailError = true;
        this.emailErrorMessage = 'Please enter valid email.';
    } else {
        this.isInvalidEmailID = false;
        this.enableNextButton = true;
        this.isEmailError = false;
        this.emailErrorMessage = '';
    }
  }

  trackValueChange(value) {
    console.log(value);
    let selectedCode = !!value ? value['code'] : '';
    this.registerSubForm.controls.countryCode.setValue(selectedCode);
    if (selectedCode === '') {
      this.codeErrorMsg = true;
    } else {
      this.codeErrorMsg = false;
    }
  }

  clearValue() {
    this.registerSubForm.controls.countryCode.setValue('');
    this.keyupCountryCode(this.registerSubForm.value.countryCode);
  }

}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from 'projects/fadv/web-common/src/public-api';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'src/app/app-config';
import { catchError, map } from 'rxjs/operators';
@Injectable()
export class TrackEventService {
  payloadForAudits: any;
  
  constructor(private localStorage: LocalStorageService,
    private _authHttp: HttpClient) {
  }

  updateTrackEvent(name, metaData?) {
    let eventName, payload;

    eventName = { stage_name : metaData.stage_name, action_value: metaData.action_value};
    payload = { action: name };
    
    this.sendEventsToAudits(name, eventName).subscribe(response => {
      console.log("Events sent to audits successfuly.....");
    }, error => {
      console.log("ERROR : Failed to send events to audits");
    });
  }
  
  sendEventsToAudits(name, body): Observable<any> {
    let headerOptions = this.getHeaderOptions();
    let auditURL = environment.apiUrl + '/api/v1/audit/log-audit';
    
    this.preparePayloadForAudits(name, body);
    
    return this._authHttp.post(auditURL, this.payloadForAudits, headerOptions)
      .pipe(
        map(res => {
          return res;
        }),
        catchError(err => {
          console.log('log audit error', err);
          return of();
        })
      );
  }
  
  convertToSpinalCase(str) {
    return str.replace(/([A-Z])/g,' $1') /*Find all cap and add space at the start*/
              .replace(/[^A-Za-z0-9]/g,' ') /*Find all non alpha numeric and replace it with space*/
              .replace(/\s{1,}/g,"-") /*Convert all spaces to -*/
              .replace(/^\-|[\-]$/g,'') /*Slice - at the start and end*/
              .toLowerCase(); /*LowerCase it*/
  }
  
  getHeaderOptions(): any {
    const authHeaders = new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
    });

    const OPTIONS = { headers: authHeaders };

    return OPTIONS;
  }

  preparePayloadForAudits(name, body) {
    let data = {
      "account_id":  this.localStorage.getItem('account_id'),
      "tag_id" : this.localStorage.getItem('tag_id'),
      "email": this.localStorage.getItem("email"),
      "visiter_id" : this.localStorage.getItem('visiter_id')
    } ;
    
    this.payloadForAudits = {
      "name" : this.convertToSpinalCase(name),
      "category" : body['stage_name'],
      "at" : new Date(),
      "by" : this.localStorage.getItem('profile_id'),
      "action" : body['action_value'],
      "system" : 'KIOSK',
      "data" : data
    }
  }
}

import { Injectable } from '@angular/core';
import { AppConfig } from '../app-config';
import { environment } from 'src/environments/environment';
import { TranslationService } from './translation/translation.service';
import { EventBusService } from './event-bus/event-bus.service';
declare let window: any;
declare let $: any;
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  compInstance: any;
  pipeTriggerNumber = 1;
  private custId: string;
  private custTag: string;
  private languageCd: string;
  private errorCode: string;
  private errorMessage: string;

  constructor(private ts: TranslationService, private es: EventBusService) {}

  public setCurrentParentInstance(instance) {
    this.compInstance = instance;
  }

  public setPipeTriggerNumber(num) {
    this.pipeTriggerNumber = num;
    this.setPipeTriggerNumberToCompInst(num);
  }

  getPipeTriggerNumber() {
    return this.pipeTriggerNumber;
  }

    // Navigate to top of device
  public navigationtopMobile() {
      console.log('call Scroll top');
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        console.log('call Scroll top qualified');
        $("html, body").animate({ scrollTop: "0" }, 500); 
      }
    }

  public setPipeTriggerNumberToCompInst(num) {
    this.compInstance.pipeTriggerNum = num;
  }

  public setUserLanguages(
    defaultLanguage = AppConfig.getDefaultLanguage(),
    languagesList
  ) {

    //Modified for rightID
    if (defaultLanguage) {
      defaultLanguage = defaultLanguage.toLowerCase();
    }

    const copyOfLang = JSON.parse(JSON.stringify(defaultLanguage));
    defaultLanguage = this.validateLanguage(defaultLanguage);
    this.ts.setLanguage(defaultLanguage);
    this.es.defaultAndMultipleLanguagesAnnouncement(
      defaultLanguage,
      copyOfLang,
      languagesList
    );
  }

  public validateLanguage(language): string {
    return language ? this.getLanguageCode(language) : AppConfig.getDefaultLanguage();
  }

  getLanguageCode(language): string {
    let dfLang = AppConfig.getDefaultLanguage();
    const langDiv = language.split('-');
    if ( langDiv.length > 1 && language.split('-')[1].toLowerCase() === 'us') {
      language = language.split('-')[0].toLowerCase();
    }
    if (AppConfig.getSupportedLanguageList()[language]) {
      dfLang = AppConfig.getSupportedLanguageList()[language];
    }
    return dfLang;
  }

  // public setLanguageFromList(language) {
  //   const ln = this.ts.getLanguage();
  //   if (ln !== language) {
  //   }
  // }

  setRouteInfo(custId, custTag, languageCd?) {
    this.custId = custId;
    this.custTag = custTag;
    if (languageCd) {
      this.languageCd = languageCd;
    }
  }

  getCustId() {
    return this.custId;
  }

  getCustTag() {
    return this.custTag;
  }

  getLanguageCd() {
    return this.languageCd;
  }

  reloadApplication(language) {
    if (!this.languageCd || language !== this.languageCd.toLowerCase()) {
      window.location = environment.client_url + `/${this.custId}/${this.custTag}?language_cd=` + language;
    }
  }

  setErrorCodeAndMsg(code, msg) {
    this.errorCode = code;
    this.errorMessage = msg;
  }

  getErrorCode() {
    return (this.errorCode) ? this.errorCode : 'BAD_REQUEST';
  }

  getErrorMessage() {
    return (this.errorMessage) ? this.errorMessage : 'Something went wrong';
  }
}

import { InjectionToken } from '@angular/core';
import { LANG_EN_NAME, LANG_EN_TRANS } from 'src/assets/i18n/language-en';
import { LANG_FR_NAME, LANG_FR_TRANS } from 'src/assets/i18n/language-fr';
import {
  LANG_FR_CA_NAME,
  LANG_FR_CA_TRANS
} from 'src/assets/i18n/language-fr-ca';
import { LANG_DE_NAME, LANG_DE_TRANS } from 'src/assets/i18n/language-de';
import {
  LANG_EN_FR_NAME,
  LANG_EN_FR_TRANS
} from 'src/assets/i18n/language-en-fr';
import {
  LANG_EN_GB_NAME,
  LANG_EN_GB_TRANS
} from 'src/assets/i18n/language-en-gb';
import { LANG_ES_NAME, LANG_ES_TRANS } from 'src/assets/i18n/language-es';
import { LANG_HU_NAME, LANG_HU_TRANS } from 'src/assets/i18n/language-hu';
import { LANG_IT_NAME, LANG_IT_TRANS } from 'src/assets/i18n/language-it';
import { LANG_JA_NAME, LANG_JA_TRANS } from 'src/assets/i18n/language-ja';
import { LANG_PL_NAME, LANG_PL_TRANS } from 'src/assets/i18n/language-pl';
import { LANG_PT_NAME, LANG_PT_TRANS } from 'src/assets/i18n/language-pt';
import {
  LANG_ZH_CN_NAME,
  LANG_ZH_CN_TRANS
} from 'src/assets/i18n/language-zh-cn';

export const TRANSLATIONS = new InjectionToken<string>( 'translations');
export const TRANSLATIONS_DB = new InjectionToken<string>( 'translations');

export const dictionary = {
  [LANG_EN_NAME]: LANG_EN_TRANS,
  [LANG_FR_NAME]: LANG_FR_TRANS,
  [LANG_FR_CA_NAME]: LANG_FR_CA_TRANS,
  [LANG_ES_NAME]: LANG_ES_TRANS,
  [LANG_EN_GB_NAME]: LANG_EN_GB_TRANS,
  [LANG_JA_NAME]: LANG_JA_TRANS,
  [LANG_ZH_CN_NAME]: LANG_ZH_CN_TRANS,
  [LANG_DE_NAME]: LANG_DE_TRANS,
  [LANG_EN_FR_NAME]: LANG_EN_FR_TRANS,
  [LANG_IT_NAME]: LANG_IT_TRANS,
  [LANG_PL_NAME]: LANG_PL_TRANS,
  [LANG_PT_NAME]: LANG_PT_TRANS,
  [LANG_HU_NAME]: LANG_HU_TRANS
};

export const TRANSLATION_PROVIDERS = [
  { provide: TRANSLATIONS, useValue: dictionary },
  { provide: TRANSLATIONS_DB, useValue: dictionary }
];

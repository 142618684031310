import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../app-config';
import { SharedService } from '../shared/shared.service';
import { Router } from '@angular/router';
import { EventBusService } from '../shared/event-bus/event-bus.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLanguages: boolean;
  languagesList = [];
  constructor(
    private es: EventBusService,
    private sharedService: SharedService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showLanguages = false;
    this.es.multipleLanguageListAnnounced$.subscribe(data => {
      this.showLanguages = data.showLanguageList;
      if (this.showLanguages) {
        this.userDefinedLangSetting(data.multipleLanguage, data.selectedLanguage);
      }
    });
  }

  userDefinedLangSetting(languageItmes, selectedLanguage) {
    const displayLanguages = AppConfig.getDisplayLanguages();
    for (const lan of languageItmes) {
      const languageWithCountryCode = this.sharedService.validateLanguage(lan.toLowerCase());
      if (displayLanguages.hasOwnProperty(languageWithCountryCode) && this.checkExistingLanguage(languageWithCountryCode)) {
        const langObj = {
          df_language: lan.toLowerCase(),
          language_name: displayLanguages[languageWithCountryCode]['languageName'],
          flag_icon_name: displayLanguages[languageWithCountryCode]['flagIconName'],
          lang_contry_cd: languageWithCountryCode,
          highlightId: (selectedLanguage.toLowerCase() === lan.toLowerCase()) ? 'activeLanguageList' : ''
        };
        this.languagesList.push(langObj);
      }
    }
  }
  updateLanguage(language) {
   this.sharedService.reloadApplication(language.df_language);
  }

  checkExistingLanguage(langCd) {
    let langNotExist = true;
    if (this.languagesList && !(this.languagesList.length > 0)) {
      langNotExist = true;
    } else {
      if (this.languagesList.find(lan => lan.lang_contry_cd === langCd)) {
        langNotExist = false;
      } else {
        langNotExist = true;
      }
  }
    return langNotExist;

  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {

  private availableLanguage = new Subject<any> ();
  public availableLanguageAnnounced$ = this.availableLanguage.asObservable();

  private multipleLanguageList = new Subject<any> ();
  public multipleLanguageListAnnounced$ = this.multipleLanguageList.asObservable();

  constructor() { }

  defaultAndMultipleLanguagesAnnouncement(defaultLanguage, copyOfLang, languagesList) {
    this.availableLanguageAnnouncement({
      language : defaultLanguage
    });

    const showLanguage = languagesList && languagesList.length > 0;
    // let newLanguagesList = [];
    if (showLanguage) {
      languagesList.push('en-us');
      // languagesList.push(copyOfLang);
      // newLanguagesList = languagesList.filter(lan => lan.toLowerCase() !== copyOfLang.toLowerCase());
    }
    this.multipleLanguageListAnnouncement({
      showLanguageList: showLanguage,
      selectedLanguage: copyOfLang,
      multipleLanguage: languagesList
    });
  }

  availableLanguageAnnouncement(obj) {
    this.availableLanguage.next(obj);
  }

  multipleLanguageListAnnouncement(obj) {
    this.multipleLanguageList.next(obj);
  }
}

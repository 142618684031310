import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LanguagePickerComponent } from './language-picker.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        LanguagePickerComponent
    ],
    providers: [],
    declarations: [
        LanguagePickerComponent
    ]
})
export class LanguagePickerModule {
}
